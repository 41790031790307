.hovercard {
  position: relative;
  display: inline-flex;
}

.hovercard-trigger {
  display: inline-flex;
  z-index: 0;
}

.hovercard-body {
  position: absolute;
  left: -9999px;
  top: 100%;
  margin-top: 8px;
  transform: translate(0, 10px);
  width: 320px;
  @apply bg-white;
  @apply border border-gray-50 p-2;
  border-radius: 5px;
  opacity: 0;
  transition: right 0s 0.4s, opacity 0.4s, transform 0.4s;
  box-shadow: 0px 0px 10px rgba(20, 20, 32, 0.1);

  &.open-right {
    left: auto;
    right: 9999px;
  }

  &:before,
  &:after {
    content: "";
    top: 0;
    background: white;
    position: absolute;
    content: "";
  }

  &:after {
    width: 50px;
    height: 10px;
    margin-top: 0;
    left: 20px;
    transform: none;
  }

  .open-right &:after {
    left: auto;
    right: 20px;
  }

  &:before {
    width: 12px;
    height: 12px;
    transform: translate(0, -50%) rotate(45deg);
    left: 26px;
    @apply border border-gray-50;
  }

  .open-right &:before {
    left: auto;
    right: 26px;
  }
}

.hovercard-trigger:hover + .hovercard-body,
.hovercard-body:hover {
  z-index: 49;
  left: -20px;
  opacity: 1;
  transform: none;
  transition: left 0s, opacity 0.4s, transform 0.4s;
}

.open-right .hovercard-trigger:hover + .hovercard-body,
.open-right .hovercard-body:hover {
  z-index: 49;
  right: -20px;
  left: auto;
  opacity: 1;
  transform: none;
  transition: right 0s, opacity 0.4s, transform 0.4s;
}
