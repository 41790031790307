.list-group {
  & > li {
    @apply p-2;
  }

  & > li {
    transition: 0.2s background-color;
  }

  & > li:hover {
    @apply bg-gray-25;
  }

  & > li.list-group-item.active {
    @apply bg-gray-25;
  }
}

.list-group-item-icon {
  .tag.gray {
    background: transparent;
    @apply text-secondary;
    transition: 0.2s background-color, 0.2s color, 0.2s color;
  }

  &:hover .tag.gray,
  .active & .tag.gray {
    @apply bg-gray-900 text-white text-primary;
  }
}
