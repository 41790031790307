.privacy {
  &.page {width: 500px; margin: auto; padding: 50px 0}
  p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: center; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  p.p2 {margin: 0.0px 0.0px 12.0px 0.0px; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000; min-height: 12.0px}
  p.p3 {margin: 0.0px 0.0px 12.0px 0.0px; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  p.p4 {margin: 0.0px 0.0px 12.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  p.p5 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 12.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  p.p7 {margin: 0.0px 0.0px 12.0px 36.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  p.p8 {margin: 0.0px 0.0px 0.0px 0.0px; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  p.p9 {margin: 0.0px 0.0px 0.0px 0.0px; font: 10.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  p.p11 {margin: 0.0px 0.0px 12.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000; min-height: 12.0px}
  p.p12 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  p.p13 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000; min-height: 12.0px}
  p.p14 {margin: 0.0px 0.0px 0.0px 0.0px; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000; min-height: 12.0px}
  li.li3 {margin: 0.0px 0.0px 12.0px 0.0px; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  li.li4 {margin: 0.0px 0.0px 12.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  li.li6 {margin: 0.0px 0.0px 6.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  li.li8 {margin: 0.0px 0.0px 0.0px 0.0px; font: 11.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  li.li10 {margin: 0.0px 0.0px 5.0px 0.0px; font: 10.0px 'Inter var'; color: #000000; -webkit-text-stroke: #000000}
  span.s2 {text-decoration: underline ; color: #0000ff}
  span.s3 {font: 11.0px Symbol}
  span.s4 {font: 12.0px Symbol}
  span.s5 {font: 7.3px 'Inter var'}
  span.s6 {font: 11.0px 'Inter var'}
  span.s7 {font: 10.0px Symbol}
  span.s8 {color: #214288}
  table.t1 {border-collapse: collapse}
  td.td1 {width: 303.0px; height: 36.0px; background-color: #eae8da; border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; padding: 4.0px 4.0px 4.0px 4.0px}
  td.td2 {width: 147.0px; height: 36.0px; background-color: #eae8da; border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; padding: 4.0px 4.0px 4.0px 4.0px}
  td.td3 {width: 303.0px; height: 43.0px; background-color: #efefef; border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; padding: 4.0px 4.0px 4.0px 4.0px}
  td.td4 {width: 147.0px; height: 43.0px; border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; padding: 4.0px 4.0px 4.0px 4.0px}
  td.td5 {width: 303.0px; height: 44.0px; background-color: #efefef; border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; padding: 4.0px 4.0px 4.0px 4.0px}
  td.td6 {width: 147.0px; height: 44.0px; border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; padding: 4.0px 4.0px 4.0px 4.0px}
  td.td7 {width: 303.0px; height: 27.0px; background-color: #efefef; border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; padding: 4.0px 4.0px 4.0px 4.0px}
  td.td8 {width: 147.0px; height: 27.0px; border-style: solid; border-width: 1.0px 1.0px 1.0px 1.0px; border-color: #b2b2b2 #b2b2b2 #b2b2b2 #b2b2b2; padding: 4.0px 4.0px 4.0px 4.0px}
  ol.ol1 {list-style: decimal inside}
  ol.ol2 {list-style: upper-alpha inside; margin-left: 30px}
  ul.ul1 {list-style-type: disc; margin-left: 30px}
}

.terms {
  &.page {width: 500px; margin: auto; padding: 50px 0}
  p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: center; font: 11.0px 'Inter var'; color: #000000}
  p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; font: 11.0px 'Inter var'; color: #000000; min-height: 13.0px}
  p.p3 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; text-indent: 36.0px; font: 11.0px 'Inter var'; color: #000000}
  p.p4 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000}
  p.p5 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000; min-height: 13.0px}
  p.p6 {margin: 0.0px 0.0px 12.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000}
  li.li6 {margin: 0.0px 0.0px 12.0px 0.0px; text-align: justify; font: 11.0px 'Inter var'; color: #000000}
  span.s1 {letter-spacing: 0.2px}
  span.s2 {color: #010000}
  span.s3 {text-decoration: underline}
  ol.ol1 {list-style: decimal inside}
  ol.ol2 {list-style: lower-alpha inside}
}
