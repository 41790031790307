.button-to {
  input[type=submit] {
    @apply py-0 px-3 inline-block;
    border-radius: 5px;
    line-height: 30px;
    font-size: 14px;
    @apply border border-gray-100 rounded;
    @apply bg-white text-secondary;
    text-align: center;
    transition: 0.2s color, 0.2s background-color, 0.2s border;
    white-space: nowrap;

  }

}

.button {
  display: inline-block;
  @apply py-0 px-3 shrink-0;
  border-radius: 5px;
  line-height: 30px;
  font-size: 14px;
  @apply border border-gray-100 rounded;
  @apply bg-white text-primary text-base py-1;
  text-align: center;
  transition: 0.2s color, 0.2s background-color, 0.2s border;
  white-space: nowrap;

  &:disabled,
  &[disabled] {
    @apply text-secondary;
    background: #fafafa;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover {
    @apply border border-gray-200;
  }

  &.full-width {
    display: block;
    width: 100%;
  }

  &.blank {
    @apply text-secondary border-transparent bg-transparent;
  }

  &.blank:hover,
  &.blank:focus {
    @apply border border-gray-200;
  }

  &.blank:disabled {
    @apply text-tertiary;
  }

  &.blue {
    @apply bg-blue text-white border-blue;
  }

  &.blue:hover,
  &.blue:focus {
    @apply border-blue-700 text-white;
  }

  &.blue:active {
    @apply bg-blue-600;
  }

  &.blue:disabled,
  &.blue[disabled] {
    @apply bg-gray-300 text-white border border-gray-300;
  }

  &.light-blue {
    @apply bg-blue-100 text-primary border-blue;
  }

  &.light-blue:hover,
  &.light-blue:focus {
    @apply border-blue-300 text-primary;
  }

  &.light-blue:active {
    @apply bg-blue-200;
  }

  &.light-blue:disabled,
  &.light-blue[disabled] {
    @apply bg-gray-300 text-white border border-gray-300;
  }

  &.red {
    @apply bg-red text-white border-red;
  }

  &.red:hover,
  &.red:focus {
    @apply border-red-700 text-white;
  }

  &.red:active {
    @apply bg-red-600;
  }

  &.red:disabled,
  &.red[disabled] {
    @apply bg-gray-50 text-white border border-gray-50;
  }

  &.icon-button {
    margin: 0;
    @apply py-0 px-1;
  }

  &.icon-button svg {
    margin: 0;
  }
}

.button-group {
  display: flex;

  .button {
    height: 32px;
    flex-grow: 1;
  }
}
