.advanced-timeline {
  border-radius: 5px;
  @apply border border-gray-50;
  display: flex;
  position: relative;
}

.advanced-timeline-left {
  @apply w-40 lg:w-56 border-r border-gray-50
}

.advanced-timeline-left-header {
  @apply border-b border-gray-50;
  display: flex;
  height: 60px;
  align-items: center;
  padding-left: 20px;
}

.advanced-timeline-menu {
  a {
    display: flex;
    align-items: center;
    @apply py-1 px-2;
  }

  a svg {
    margin-right: 10px;
  }

  a:hover {
    @apply bg-gray-25;
  }
}

.advanced-timeline-left-body {
  @apply py-1 px-0;
}

.advanced-timeline-release {
  display: flex;
  align-items: center;
  @apply py-2 px-4;
  height: 60px;

  &:hover,
  &.active {
    @apply bg-gray-25;
  }

  .icon {
    width: 20px;
  }

  .text {
    display: block;
    margin-left: 8px;
    min-width: 0;
  }

  .text h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text span {
    @apply text-tertiary;
  }
}

.advanced-timeline-right {
  overflow-x: auto;
  flex: 1;
  position: relative;
}

.advanced-timeline-right-header {
  height: 60px;
  white-space: nowrap;
  display: inline-flex;
  padding-left: 20px;
  @apply border-b border-gray-50;
  min-width: 100%;
}

.advanced-timeline-right-header-month {
  width: 66px;
  display: block;
  line-height: 60px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  @apply text-tertiary;
}

.advanced-timeline-right-body {
  padding-top: 5px;
  padding-left: 20px;
}

.advanced-timeline-arrows {
  top: 60px;
  position: absolute;
  right: 10px;
  width: 30px;
  @apply py-1 px-0;
}

.advanced-timeline-arrow {
  @apply bg-gray-25;
  height: 40px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.advanced-timeline-right-body-row {
  height: 59px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.advanced-timeline-bar-box {
  display: flex;
  align-items: center;
  height: 30px;
  @apply py-0 px-2;
  border-radius: 5px;
  min-width: 99px;

  &.gray {
    background-color: #f3f3f4;
  }

  time {
    font-size: 12px;
    @apply text-secondary;
  }
}

.advanced-timeline-bar {
  flex: 1;
  height: 10px;
  border-radius: 5px;
  margin: 0 10px;
  min-width: 10px;

  &.purple {
    @apply bg-purple;
  }

  &.red {
    @apply bg-red;
  }

  &.orange {
    @apply bg-orange;
  }

  &.yellow {
    @apply bg-yellow;
  }

  &.green {
    @apply bg-green;
  }

  &.blue {
    @apply bg-blue;
  }
}
