.editor {
    .search {
        @apply p-1.5 pl-7 text-primary text-body h-9 rounded-none;
        background: 10px 12px no-repeat url('data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M13.5815%2012.8744C14.4664%2011.8291%2015%2010.4769%2015%209C15%205.68629%2012.3137%203%209%203C5.68629%203%203%205.68629%203%209C3%2012.3137%205.68629%2015%209%2015C10.4769%2015%2011.8291%2014.4664%2012.8744%2013.5815L16.1464%2016.8536C16.3417%2017.0488%2016.6583%2017.0488%2016.8536%2016.8536C17.0488%2016.6583%2017.0488%2016.3417%2016.8536%2016.1464L13.5815%2012.8744ZM14%209C14%2011.7614%2011.7614%2014%209%2014C6.23858%2014%204%2011.7614%204%209C4%206.23858%206.23858%204%209%204C11.7614%204%2014%206.23858%2014%209Z%22%20fill%3D%22%23B9B9BC%22%2F%3E%3C%2Fsvg%3E'), white;
    }
    .new {
        font-size: 14px;
        color: #2F80ED;
        border-top: solid 1px rgba(20, 20, 32, 0.1);
        padding-left: 35px;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        /* load this as an SVG file? */
        background: 10px center no-repeat url('data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2214%22%20viewBox%3D%220%200%2013%2014%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M11%209.74284C10.0491%2010.5282%208.82963%2011%207.5%2011C4.65668%2011%202.31729%208.84244%202.02972%206.0752C1.43165%206.27249%201%206.83584%201%207.5V11.5C1%2012.3284%201.67157%2013%202.5%2013H9.5C10.3284%2013%2011%2012.3284%2011%2011.5V9.74284ZM12%208.66308C12.63%207.76841%2013%206.67741%2013%205.5C13%202.46243%2010.5376%200%207.5%200C4.61519%200%202.24912%202.221%202.01844%205.04634C0.868145%205.27079%200%206.28401%200%207.5V11.5C0%2012.8807%201.11929%2014%202.5%2014H9.5C10.8807%2014%2012%2012.8807%2012%2011.5V8.66308ZM4%205.5C4%205.77614%204.22386%206%204.5%206H7V8.5C7%208.77614%207.22386%209%207.5%209C7.77614%209%208%208.77614%208%208.5V6H10.5C10.7761%206%2011%205.77614%2011%205.5C11%205.22386%2010.7761%205%2010.5%205H8V2.5C8%202.22386%207.77614%202%207.5%202C7.22386%202%207%202.22386%207%202.5V5H4.5C4.22386%205%204%205.22386%204%205.5Z%22%20fill%3D%22%232F80ED%22%2F%3E%0A%3C%2Fsvg%3E%0A');

        border: solid 1px rgba(20, 20, 32, 0.1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        &:first-child {
            border-top: none;
        }
    }
}
