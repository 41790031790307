.inline-bullet-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li,
  a {
    display: flex;
    align-items: center;
  }

  li,
  li:before {
    vertical-align: middle;
  }

  li + li:before {
    content: "";
    width: 4px;
    height: 4px;
    @apply bg-gray-200;
    border-radius: 50%;
    margin: 0 10px 0 8px;
    display: inline-block;
  }

  a:hover {
    text-decoration: underline;
  }
}
