.project {
  h3 {
    margin-bottom: 10px;
  }
}

.project-timeline-body {
  @apply py-2 px-0;
  overflow-x: auto;
}

.project-timeline-segment {
  white-space: nowrap;
}
