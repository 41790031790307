#flash {
  position: fixed;
  left: 50%;
  bottom: 20px;
  margin: 0 auto;
  z-index: 100;
  will-change: transform;
  visibility: visible;
  -webkit-animation: flashOut 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: flashOut 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation-fill-mode: forwards;

  animation-delay:2s;
  -webkit-animation-delay:1s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;

}

.flash-message {
  font-size: 12px;
  width: max-content;
  @apply py-1 px-2;

  &.notice,
  &.success {
    @apply bg-green-200 border-green-300 border border-solid;
    color: #2B481A;
    border-radius: 5px;
  }
}

@-webkit-keyframes flashOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
}
