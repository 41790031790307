.accordion-section {
  & + & {
    @apply border-t border-gray-50;
  }
}

.accordion-section-header {
  @apply px-4 py-0;
  transition: 0.5s background-color, 0.5s color;
  cursor: pointer;
  height: 60px;

  .active &,
  &:hover {
    @apply bg-gray-25 text-primary;
  }

  .active &:hover {
    @apply text-secondary;
  }
}

.accordion-section-body {
  @apply bg-gray-25;
  border-bottom: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s max-height, 0.5s border-color;

  .active & {
    border-color: transparent;
    /*max-height: 140px;*/
  }

  .fully-active & {
    overflow: initial;
  }
}

.accordion-section-body-content {
  @apply pt-0 pr-5 pb-4 pl-10;
}
