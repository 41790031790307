import { styled } from "@linaria/react";
export const BubblesOverlayEditorStyle = styled.div`
    display: flex;
    flex-wrap: wrap;

    .boe-bubble {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 100px;

        padding: 0 8px;
        height: 20px;

        background-color: var(--gdg-bg-bubble);
        color: var(--gdg-text-dark);
        margin: 2px;
    }

    textarea {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0px;
        height: 0px;

        opacity: 0;
    }
`;
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsiL1VzZXJzL3B6aWNoL1Byb2plY3RzL3B6Y2ZnL2dsaWRlLWRhdGEtZ3JpZC9wYWNrYWdlcy9jb3JlL3NyYy9kYXRhLWdyaWQtb3ZlcmxheS1lZGl0b3IvcHJpdmF0ZS9idWJibGVzLW92ZXJsYXktZWRpdG9yLXN0eWxlLnRzeCJdLAogICJzb3VyY2VzQ29udGVudCI6IFsiaW1wb3J0IHsgc3R5bGVkIH0gZnJvbSBcIkBsaW5hcmlhL3JlYWN0XCI7XG5cbmV4cG9ydCBjb25zdCBCdWJibGVzT3ZlcmxheUVkaXRvclN0eWxlID0gc3R5bGVkLmRpdmBcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtd3JhcDogd3JhcDtcblxuICAgIC5ib2UtYnViYmxlIHtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgICAgICAgYm9yZGVyLXJhZGl1czogMTAwcHg7XG5cbiAgICAgICAgcGFkZGluZzogMCA4cHg7XG4gICAgICAgIGhlaWdodDogMjBweDtcblxuICAgICAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1nZGctYmctYnViYmxlKTtcbiAgICAgICAgY29sb3I6IHZhcigtLWdkZy10ZXh0LWRhcmspO1xuICAgICAgICBtYXJnaW46IDJweDtcbiAgICB9XG5cbiAgICB0ZXh0YXJlYSB7XG4gICAgICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICAgICAgdG9wOiAwcHg7XG4gICAgICAgIGxlZnQ6IDBweDtcbiAgICAgICAgd2lkdGg6IDBweDtcbiAgICAgICAgaGVpZ2h0OiAwcHg7XG5cbiAgICAgICAgb3BhY2l0eTogMDtcbiAgICB9XG5gO1xuIl0sCiAgIm1hcHBpbmdzIjogIkFBQUE7QUFFTyxhQUFNLDRCQUE0QixPQUFPO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7IiwKICAibmFtZXMiOiBbXQp9Cg==*/