.indicator-bar {
  width: 4px;
  height: 16px;
  @apply bg-gray-50;
  border-radius: 2px;

  &.blue {
    @apply bg-blue;
  }

  & + & {
    margin-left: 3px;
  }
}
