input[type=text].searchfield {
  @apply border-0 p-5 text-xl focus:shadow-none focus:ring-0 text-primary rounded-lg;
}

#searchModal.invisible .modalBody{
  @apply opacity-0 translate-y-1;
}

#searchModal.invisible .modalBackground{
  @apply opacity-0;
}

#searchModal .searchResult.active{
  @apply bg-blue-100;
}

input.search {
  @apply bg-blue text-white border-blue;

  &:disabled,
  &[disabled] {
    @apply text-secondary;
    background: #fafafa;
    cursor: not-allowed;
    pointer-events: none;
  }
}
