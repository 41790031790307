.pdf-viewer {
  
  
  
  
  
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.pdf-viewer-main {
  height: 100vh;
  width: 100vw;
  background: #eeeeee;
  overflow-y: auto;

  @media (min-width: 1024px) {
    width: calc(100vw - 260px);
  }

  img {
    margin: 20px auto;
    max-width: calc(100% - 40px);
  }

  embed {
    width: 100%;
    height: 100%;
  }
}

.pdf-viewer-sidebar {
  @apply lg:p-6;
  display: none;

  @media (min-width: 1024px) {
    height: 100vh;
    width: 30%;
    min-width: 260px;
    background: white;
    overflow-y: auto;
    display: block;
  }
}

.pdf-viewer-header {
  margin-bottom: 40px;

  h2 {
    font-size: 18px;
  }
}

.sidebar-comments > * > div {
  margin-bottom: 30px;
}
