.blank-state {
  @apply text-secondary py-6 px-4;

  * {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    font-size: 16px;
    margin-bottom: -5px;
  }

  img,
  svg {
    margin-left: auto;
    margin-right: auto;
  }
}

.blank-state-content {
  max-width: 700px;
  margin: 0 auto;
}
