@import "inter.css";
@import "terms.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import 'swiper/swiper-bundle.min.css';
@import "tailwindcss/utilities";
@import "./components/accordion-section.css";
@import "./components/advanced-timeline.css";
@import "./components/auth.css";
@import "./components/autocomplete-field.css";
@import "./components/avatar.css";
@import "./components/blank-state.css";
@import "./components/bom.css";
@import "./components/bom-table.css";
@import "./components/button.css";
@import "./components/card-item.css";
@import "./components/card.css";
@import "./components/collapsible-list.css";
@import "./components/dropdown.css";
@import "./components/editor.css";
@import "./components/error-box.css";
@import "./components/flash.css";
@import "./components/flatpickr.css";
@import "./components/footer.css";
@import "./components/forms.css";
@import "./components/hovercard.css";
@import "./components/icon-component.css";
@import "./components/indicator-bar.css";
@import "./components/inline-bullet-list.css";
@import "./components/launch-animations.css";
@import "./components/list-group.css";
@import "./components/markdown.css";
@import "./components/masonry.css";
@import "./components/modals.css";
@import "./components/pagination.css";
@import "./components/pdf-viewer.css";
@import "./components/progress.css";
@import "./components/project.css";
@import "./components/scrolling-text-container.css";
@import "./components/search.css";
@import "./components/sub-header.css";
@import "./components/tooltip.css";
@import "./components/tribute.css";
@import "./components/trix.css";
@import "./components/upload-thumb.css";
@import "@glideapps/glide-data-grid/dist/index.css";

.dz-drag-hover {
  @apply border-blue bg-blue-100 text-blue
}


.StripeElement {
  @apply py-2 px-3 border border-gray-100 rounded
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

body {
  @apply text-body font-normal text-secondary;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  width: calc(100% - 40px);
  max-width: 800px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: calc(100% - 80px);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-primary font-semibold text-body;
}

a {
  @apply text-primary no-underline;
}


input,
select,
button,
textarea {
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  font-size: 16px;

  &:focus {
    outline: none;
  }
}

input[type="date"],
input[type="email"],
input[type="text"],
input[type="password"],
input[type="search"],
input[type="url"],
input[type="tel"],
textarea,
select {
  @apply block w-full text-base;
}

a,
button,
input[type="submit"],
input[type="checkbox"],
input[type="radio"] {
  @apply cursor-pointer;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: none;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(230, 230, 230);
  outline: none;
  border-radius: 10px;
}


.divider {
  @apply bg-gray-50;
  border: none;
  height: 1px;
  width: 100%;

  &.spaced {
    margin: 15px 0;
  }

  &.spaced-large {
    margin: 25px 0;
  }
}


.center {
  @apply mx-auto;
  max-width: 1240px;
  width: calc(100% - 40px);

  @media (min-width: 1024px) {
    width: calc(100% - 100px);
  }

  @media (min-width: 1280px) {
    width: calc(100% - 140px);
  }

  &.full-width {
    max-width: none;
  }
}

input[type=text].projectfilter {
  @apply border-0 p-0 pl-1 focus:shadow-none focus:ring-0 text-primary;
}

body.\!cursor-col-resize .cursor-pointer {
  @apply cursor-col-resize
}

.swiper-pagination {
  position: relative;
  text-align: center;
  transition: .3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination-bullet {
  @apply w-12 h-1 rounded bg-white m-1 relative
}

.swiper-pagination-bullet-active {
  @apply w-12 h-1 rounded bg-white m-1 relative
}

.swiper-pagination-bullet:after {
  content: '';
  position: absolute;
  top: -15px;
  bottom: -15px;
  left: -15px;
  right: -15px;
}

.swiper-pagination-bullet-active:after {
  content: '';
  position: absolute;
  top: -15px;
  bottom: -15px;
  left: -15px;
  right: -15px;
}
