.icon-component {
  margin: 0;
  @apply text-secondary;

  & + & {
    margin-top: 8px;
  }
}

.icon-component-left {
  height: 20px;
  align-items: center;
  display: flex;
}

.icon-component-right {
}

.icon-component-header {
  line-height: 20px;
}

.icon-component-body {
  font-weight: 400;
}
