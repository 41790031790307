.bom-table {
  .table-table tr {
    @apply border-b-0 hover:bg-gray-25
  }
  .table-table tr td {
    @apply py-1;
  }
  .expand_toggle svg{
    transition:all 0.2s ease-in-out;
  }
  tr.collapsed .expand_toggle svg{
    transform: rotate(-90deg)
  }
  tr.collapsed .parent svg {
    display:none;
  }
}

.hierarchical.bom-table {
  .table-table tr td {
    @apply py-0;
  }
  .table-table tr:first-of-type td {
    @apply pt-1
  }
  .table-table tr:last-of-type td {
    @apply pb-1
  }
}