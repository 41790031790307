.scrolling-text-container {
  animation: scroll-right linear infinite;
}

.scrolling-text-container-left {
  animation: scroll-left linear infinite;
}

/* FIXME: Any way we can do this with tailwind? */
.animation-duration-106 {
  animation-duration: 106s;
}
.animation-duration-110 {
  animation-duration: 110s;
}
.animation-duration-111 {
  animation-duration: 111s;
}
.animation-duration-116 {
  animation-duration: 116s;
}
.animation-duration-120 {
  animation-duration: 120s;
}
.animation-duration-123 {
  animation-duration: 123s;
}
.animation-duration-124 {
  animation-duration: 124s;
}
.animation-duration-142 {
  animation-duration: 142s;
}
.animation-duration-130 {
  animation-duration: 130s;
}
.animation-duration-131 {
  animation-duration: 131s;
}
.animation-duration-134 {
  animation-duration: 134s;
}

/* Disable animation when prefers-reduced-motion is set to reduce */
@media (prefers-reduced-motion: reduce) {
  .scrolling-text-container {
    animation: none;
  }

  .scrolling-text-container-left {
    animation: none;
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}
