.trix-content {
  @apply break-all;

  & div > * + * {
    margin-top: 15px;
  }

  li {
    margin-left: 18px;
  }

  ul li {
    list-style: disc;
  }

  ol li {
    list-style: decimal;
  }
}

.trix-content action-text-attachment:before {
  content:  '@';
}
