.login-form {
  max-width: 350px;
  width: 90%;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    text-align: center;
    font-size: 21px;
    margin-bottom: 40px;
  }
}

.auth-links {
  margin-top: 15px;

  a {
    transition: 0.2s color;
  }

  a:hover {
    @apply text-primary;
  }
}
