.card {
  @apply border border-gray-50 rounded;

  &.white {
    @apply bg-white;
  }

  &.gray {
    @apply bg-gray-25;
  }

  .gray {
    @apply bg-gray-25;
  }

  & + & {
    margin-top: 20px;
  }
}
