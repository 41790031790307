.bom-typing-animation {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  animation: blink-caret 0.75s step-end infinite;
  border-right: 0.15em solid black;
}

.bom-fallback-image {
  height: 0;
}

.bom-bg {
  background-image: url('index/BOM_bg.png');
}

.part-number-font {
  font-size: 12vw;
  line-height: 12vw;
}

.highlight-transition {
  @apply transition-[left,width];
}

@media only screen and (min-width: 768px) {
  .bom-typing-font {
    font-size: 1vw;
    line-height: 1vw;
  }

  .bom-typing-font-container {
    top: 18.75%;
  }

  .part-number-font {
    font-size: 2.8vw;
    line-height: 2.8vw;
  }
}

@media only screen and (min-width: 80rem) {
  .bom-typing-font {
    font-size: 1rem;
    line-height: 1rem;
  }

  .bom-typing-font-container {
    top: 18.75%;
  }

  .part-number-font {
    font-size: 3rem;
    line-height: 3rem;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: black;
  }
}

.dri-animated-button {
  animation: dri-animated-button 1s ease;
}

@keyframes dri-animated-button {
  30% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.progress-segment-one {
  animation: progress-segment-one 3s normal forwards;
}

@keyframes progress-segment-one {
  0% {
    width: 0;
  }

  100% {
    width: 76%;
  }
}

.progress-segment-two {
  animation: progress-segment-two 3s normal forwards;
}

@keyframes progress-segment-two {
  0% {
    width: 0;
  }

  100% {
    width: 10%;
  }
}

.progress-segment-three {
  animation: progress-segment-three 3s normal forwards;
}

@keyframes progress-segment-three {
  0% {
    width: 0;
  }

  100% {
    width: 2%;
  }
}

.progress-segment-four {
  animation: progress-segment-four 3s normal forwards;
}

@keyframes progress-segment-four {
  0% {
    width: 0;
  }

  100% {
    width: 1%;
  }
}

@media (prefers-reduced-motion: reduce) {
  .bom-fallback-image {
    height: 100%;
  }

  .highlight-transition {
    transition: none;
  }

  .bom-typing-animation {
    animation: none;
  }

  .dri-animated-button {
    animation: none;
  }

  .progress-segment-one {
    animation: none;
    width: 76%;
  }

  .progress-segment-two {
    animation: none;
    width: 10%;
  }

  .progress-segment-three {
    animation: none;
    width: 2%;
  }

  .progress-segment-four {
    animation: none;
    width: 1%;
  }
}

.desktop-bg {
  background-image: url('index/Desktop_bg.jpg');
}

.desktop-slide-1 {
  background-image: url('index/Desktop_slide1.svg');
}

.desktop-slide-2 {
  background-image: url('index/Desktop_slide2.svg');
}

.desktop-slide-3 {
  background-image: url('index/Desktop_slide3.svg');
}

.icon-nx {
  background-image: url('index/Icon_nx.png');
}

.icon-solidworks {
  background-image: url('index/Icon_solidworks.png');
}

.icon-altium {
  background-image: url('index/Icon_altium.png');
}

.viewer-enclosure {
  background-image: url('index/Preview/Viewer_Enclosure.png');
}

.viewer-dwg {
  background-image: url('index/Preview/Viewer_Dwg.svg');
}

.viewer-layout {
  background-image: url('index/Preview/Viewer_Layout.png');
}

.viewer-schematic {
  background-image: url('index/Preview/Viewer_Schematic.png');
}

.dri-shadow {
  box-shadow: inset 0 0 32px rgba(0, 0, 0, 0.2);
}

.vendor-bom-access {
  box-shadow: inset 0 0 32px rgba(105, 13, 13, 0.2);
}
