.trix-button--icon-heading-1,
.trix-button--icon-heading-2,
.trix-button--icon-heading-3,
.trix-button--icon-link,
.trix-button--icon-decrease-nesting-level,
.trix-button--icon-increase-nesting-level,
.trix-button--icon-undo,
.trix-button--icon-redo {
  display: none;
}

trix-editor > div {
  @apply flex;
}
trix-editor:empty:not(:focus)::before {
  color: rgba(20, 20, 32, 0.3);
}
trix-editor:hover {
  border-color: rgba(20, 20, 32, 0.3);
}
trix-editor:focus {
  border-color: #2f80ed;
}
trix-toolbar * {
  box-sizing: border-box;
}
trix-toolbar .trix-button-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
trix-toolbar .trix-button-group {
  position: relative;
  z-index: 0;
  display: inline-flex;
}
trix-toolbar .trix-button-group:not(:first-child) {
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0;
  }
}
trix-toolbar .trix-button-group-spacer {
  flex-grow: 1;
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group-spacer {
    display: none;
  }
}
trix-toolbar .trix-button {
  border-radius: 0.25rem;
  position: relative;
  float: left;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  font-weight: 600;
  white-space: nowrap;
  @apply py-0 px-1;
  width: 26px;
  height: 20px;

  margin: 0;
  outline: none;
  background: transparent;
}

trix-toolbar .trix-button:hover {
  background: #4f4f57;
}
trix-toolbar .trix-button:not(:first-child) {
}
trix-toolbar .trix-button.trix-active {
  background: #6f6f76;
  color: white;
}
trix-toolbar .trix-button:not(:disabled) {
  cursor: pointer;
}
trix-toolbar .trix-button:disabled {
  color: rgba(0, 0, 0, 0.125);
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button {
    letter-spacing: -0.01em;
    @apply py-0 px-1 px-0.5;
  }
}
trix-toolbar .trix-button--icon {
  font-size: inherit;
  width: 2.6em;
  height: 1.6em;
  max-width: calc(0.8em + 4vw);
  text-indent: -9999px;
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon {
    height: 2em;
    max-width: calc(0.8em + 3.5vw);
  }
}
trix-toolbar .trix-button--icon::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon::before {
    right: 6%;
    left: 6%;
  }
}
trix-toolbar .trix-button--icon.trix-active::before {
  opacity: 1;
}
trix-toolbar .trix-button--icon:disabled::before {
  opacity: 0.125;
}
trix-toolbar .trix-button--icon-attach::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M16.5%206v11.5a4%204%200%201%201-8%200V5a2.5%202.5%200%200%201%205%200v10.5a1%201%200%201%201-2%200V6H10v9.5a2.5%202.5%200%200%200%205%200V5a4%204%200%201%200-8%200v12.5a5.5%205.5%200%200%200%2011%200V6h-1.5z%22%2F%3E%3C%2Fsvg%3E);
  top: 8%;
  bottom: 4%;
}
trix-toolbar .trix-button--icon-bold::before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 4H13C14.6569 4 16 5.34315 16 7C16 7.92461 15.5817 8.75153 14.924 9.30184C16.1473 9.84735 17 11.0741 17 12.5C17 14.433 15.433 16 13.5 16H11.5H8.5H7.5H6.5H5.5C5.22386 16 5 15.7761 5 15.5C5 15.2239 5.22386 15 5.5 15H6V5H5.5C5.22386 5 5 4.77614 5 4.5C5 4.22386 5.22386 4 5.5 4H6.5H8.5H11ZM9 9V5H11C12.1046 5 13 5.89543 13 7C13 8.10457 12.1046 9 11 9H9ZM9 10H11H11.5C12.8807 10 14 11.1193 14 12.5C14 13.8807 12.8807 15 11.5 15H9V10Z' fill='white'%3E%3C/path%3E%3C/svg%3E");
}
trix-toolbar .trix-button--icon-italic::before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 4C9.72386 4 9.5 4.22386 9.5 4.5C9.5 4.77614 9.72386 5 10 5H10.2861L6.64979 15H6C5.72386 15 5.5 15.2239 5.5 15.5C5.5 15.7761 5.72386 16 6 16H7H9H10C10.2761 16 10.5 15.7761 10.5 15.5C10.5 15.2239 10.2761 15 10 15H9.71385L13.3502 5H14C14.2761 5 14.5 4.77614 14.5 4.5C14.5 4.22386 14.2761 4 14 4H13H11H10Z' fill='white'%3E%3C/path%3E%3C/svg%3E");
}
trix-toolbar .trix-button--icon-link::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 10C4 8.51277 5.08221 7.2783 6.50209 7.04113C6.77446 6.99563 7 6.77614 7 6.5C7 6.22386 6.77502 5.99672 6.50099 6.03082C4.52736 6.27643 3 7.95985 3 10C3 12.2091 4.79086 14 7 14H8C10.0402 14 11.7236 12.4726 11.9692 10.499C12.0033 10.225 11.7761 10 11.5 10C11.2239 10 11.0044 10.2255 10.9589 10.4979C10.7217 11.9178 9.48723 13 8 13H7C5.34315 13 4 11.6569 4 10ZM12 6C9.95985 6 8.27643 7.52736 8.03082 9.50099C7.99672 9.77502 8.22386 10 8.5 10C8.77614 10 8.99563 9.77446 9.04113 9.50209C9.2783 8.08221 10.5128 7 12 7H13C14.6569 7 16 8.34315 16 10C16 11.4872 14.9178 12.7217 13.4979 12.9589C13.2255 13.0044 13 13.2239 13 13.5C13 13.7761 13.225 14.0033 13.499 13.9692C15.4726 13.7236 17 12.0402 17 10C17 7.79086 15.2091 6 13 6H12Z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E");
}
trix-toolbar .trix-button--icon-strike::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.73%2014l.28.14c.26.15.45.3.57.44.12.14.18.3.18.5%200%20.3-.15.56-.44.75-.3.2-.76.3-1.39.3A13.52%2013.52%200%200%201%207%2014.95v3.37a10.64%2010.64%200%200%200%204.84.88c1.26%200%202.35-.19%203.28-.56.93-.37%201.64-.9%202.14-1.57s.74-1.45.74-2.32c0-.26-.02-.51-.06-.75h-5.21zm-5.5-4c-.08-.34-.12-.7-.12-1.1%200-1.29.52-2.3%201.58-3.02%201.05-.72%202.5-1.08%204.34-1.08%201.62%200%203.28.34%204.97%201l-1.3%202.93c-1.47-.6-2.73-.9-3.8-.9-.55%200-.96.08-1.2.26-.26.17-.38.38-.38.64%200%20.27.16.52.48.74.17.12.53.3%201.05.53H7.23zM3%2013h18v-2H3v2z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-quote::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M6%2017h3l2-4V7H5v6h3zm8%200h3l2-4V7h-6v6h3z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-heading-1::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%209v3H9v7H6v-7H3V9h9zM8%204h14v3h-6v12h-3V7H8V4z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-code::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.2%2012L15%2015.2l1.4%201.4L21%2012l-4.6-4.6L15%208.8l3.2%203.2zM5.8%2012L9%208.8%207.6%207.4%203%2012l4.6%204.6L9%2015.2%205.8%2012z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-bullet-list::before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.5 5C3.67157 5 3 5.67157 3 6.5C3 7.32843 3.67157 8 4.5 8C5.32843 8 6 7.32843 6 6.5C6 5.67157 5.32843 5 4.5 5ZM8.5 5C8.22386 5 8 5.22386 8 5.5C8 5.77614 8.22386 6 8.5 6H16.5C16.7761 6 17 5.77614 17 5.5C17 5.22386 16.7761 5 16.5 5H8.5ZM8.5 7C8.22386 7 8 7.22386 8 7.5C8 7.77614 8.22386 8 8.5 8H13.5C13.7761 8 14 7.77614 14 7.5C14 7.22386 13.7761 7 13.5 7H8.5ZM4.5 12C3.67157 12 3 12.6716 3 13.5C3 14.3284 3.67157 15 4.5 15C5.32843 15 6 14.3284 6 13.5C6 12.6716 5.32843 12 4.5 12ZM8.5 12C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H16.5C16.7761 13 17 12.7761 17 12.5C17 12.2239 16.7761 12 16.5 12H8.5ZM8.5 14C8.22386 14 8 14.2239 8 14.5C8 14.7761 8.22386 15 8.5 15H11.5C11.7761 15 12 14.7761 12 14.5C12 14.2239 11.7761 14 11.5 14H8.5Z' fill='white'%3E%3C/path%3E%3C/svg%3E");
}
trix-toolbar .trix-button--icon-number-list::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M2%2017h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1%203h1.8L2%2013.1v.9h3v-1H3.2L5%2010.9V10H2v1zm5-6v2h14V5H7zm0%2014h14v-2H7v2zm0-6h14v-2H7v2z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-undo::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.5%208c-2.6%200-5%201-6.9%202.6L2%207v9h9l-3.6-3.6A8%208%200%200%201%2020%2016l2.4-.8a10.5%2010.5%200%200%200-10-7.2z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-redo::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.4%2010.6a10.5%2010.5%200%200%200-16.9%204.6L4%2016a8%208%200%200%201%2012.7-3.6L13%2016h9V7l-3.6%203.6z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-decrease-nesting-level::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-8.3-.3l2.8%202.9L6%2014.2%204%2012l2-2-1.4-1.5L1%2012l.7.7zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-increase-nesting-level::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-6.9-1L1%2014.2l1.4%201.4L6%2012l-.7-.7-2.8-2.8L1%209.9%203.1%2012zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-dialogs {
  position: relative;
}
trix-toolbar .trix-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 0.75em;
  @apply py-3 px-2;
  background: #fff;
  box-shadow: 0 0.3em 1em #ccc;
  border-top: 2px solid #888;
  border-radius: 5px;
  z-index: 5;
}
trix-toolbar .trix-input--dialog {
  font-size: inherit;
  font-weight: normal;
  @apply py-1 px-2;
  margin: 0 10px 0 0;
  border-radius: 3px;
  border: 1px solid #bbb;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
trix-toolbar .trix-input--dialog.validate:invalid {
  box-shadow: #f00 0px 0px 1.5px 1px;
}
trix-toolbar .trix-button--dialog {
  font-size: inherit;
  @apply p-1;
  border-bottom: none;
}
trix-toolbar .trix-dialog--link {
  max-width: 600px;
}
trix-toolbar .trix-dialog__link-fields {
  display: flex;
  align-items: baseline;
}
trix-toolbar .trix-dialog__link-fields .trix-input {
  flex: 1;
}
trix-toolbar .trix-dialog__link-fields .trix-button-group {
  flex: 0 0 content;
  margin: 0;
}
trix-toolbar {
  overflow: hidden;
  border: 1px solid #3f3f49;
  background: #3f3f49;
  border-radius: 0.5rem;
  @apply p-1;
  position: absolute;
  color: #fff;
  z-index: 30;
  display: hidden;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.trix-button-group--file-tools,
trix-toolbar [data-trix-attribute="heading1"],
trix-toolbar [data-trix-attribute="quote"],
trix-toolbar [data-trix-attribute="code"] {
  display: none !important;
}

trix-editor ul {
  list-style-type: disc;
  list-style-position: inside;
}
trix-editor [data-trix-mutable]:not(.attachment__caption-editor) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

trix-editor [data-trix-mutable]::-moz-selection,
trix-editor [data-trix-cursor-target]::-moz-selection,
trix-editor [data-trix-mutable] ::-moz-selection {
  background: none;
}
trix-editor [data-trix-mutable]::selection,
trix-editor [data-trix-cursor-target]::selection,
trix-editor [data-trix-mutable] ::selection {
  background: none;
}

trix-editor [data-trix-mutable].attachment__caption-editor:focus::-moz-selection {
  background: highlight;
}
trix-editor [data-trix-mutable].attachment__caption-editor:focus::selection {
  background: highlight;
}

trix-editor [data-trix-mutable].attachment.attachment--file {
  box-shadow: 0 0 0 2px highlight;
  border-color: transparent;
}

trix-editor figure.attachment.attachment--content {
  @apply font-medium text-blue;
}


trix-editor [data-trix-mutable].attachment img {
  box-shadow: 0 0 0 2px highlight;
}
trix-editor .attachment {
  position: relative;
}
trix-editor .attachment:hover {
  cursor: default;
}
trix-editor .attachment--preview .attachment__caption:hover {
  cursor: text;
}
trix-editor .attachment__progress {
  position: absolute;
  z-index: 1;
  height: 20px;
  top: calc(50% - 10px);
  left: 5%;
  width: 90%;
  opacity: 0.9;
  transition: opacity 200ms ease-in;
}
trix-editor .attachment__progress[value="100"] {
  opacity: 0;
}
trix-editor .attachment__caption-editor {
  display: inline-block;
  width: 100%;
  margin: 0;
  @apply p-0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
trix-editor .attachment__toolbar {
  position: absolute;
  z-index: 1;
  top: -0.9em;
  left: 0;
  width: 100%;
  text-align: center;
}
trix-editor .trix-button-group {
  display: inline-flex;
}
trix-editor .trix-button {
  position: relative;
  float: left;
  color: #666;
  white-space: nowrap;
  font-size: 80%;
  @apply py-0 px-2;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
}
trix-editor .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}
trix-editor .trix-button.trix-active {
  background: #cbeefa;
}
trix-editor .trix-button:not(:disabled) {
  cursor: pointer;
}
trix-editor .trix-button--remove {
  text-indent: -9999px;
  display: inline-block;
  @apply p-0;
  outline: none;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid highlight;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}
trix-editor .trix-button--remove::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  content: "";
  background-image: url(data:image/svg+xml,%3Csvg%20height%3D%2224%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.4L17.6%205%2012%2010.6%206.4%205%205%206.4l5.6%205.6L5%2017.6%206.4%2019l5.6-5.6%205.6%205.6%201.4-1.4-5.6-5.6z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3C%2Fsvg%3E);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}
trix-editor .trix-button--remove:hover {
  border-color: #333;
}
trix-editor .trix-button--remove:hover::before {
  opacity: 1;
}
trix-editor .attachment__metadata-container {
  position: relative;
}
trix-editor .attachment__metadata {
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%, 0);
  max-width: 90%;
  @apply py-0.5 px-1;
  font-size: 0.8em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}
trix-editor .attachment__metadata .attachment__name {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
trix-editor .attachment__metadata .attachment__size {
  margin-left: 0.2em;
  white-space: nowrap;
}

trix-toolbar {
  display: none;
}

.trix-field {
  width: 100%;
  box-sizing: border-box;

  trix-editor {
    width: 100%;
  }
}

trix-editor {
  @apply p-2;
  min-height: 24px;

  &.customized-min-height {
    min-height: 15em;
  }
}
trix-editor > div {
  @apply flex;
}
.comment-wrapper {
  @apply p-5;
}
.comment-wrapper {
  trix-editor {
    border-color: transparent;
    @apply p-1;
  }
}

fieldset.card,
#modal trix-editor {
  overflow-y: auto !important;
}

fieldset.card {
  height: 200px !important;
  max-height: 200px !important;
}

#modal trix-editor {
  height: 175px !important;
  max-height: 175px !important;
}
