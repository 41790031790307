

.bom-header .bom-icon {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 5.5C6 4.67157 6.67157 4 7.5 4H14.2676C14.0974 4.29417 14 4.63571 14 5V5.5V15C14 15.5523 13.5523 16 13 16C12.4477 16 12 15.5523 12 15V14.5C12 14.2239 11.7761 14 11.5 14H6V5.5ZM7.5 3H16C17.1046 3 18 3.89543 18 5V5.5C18 5.77614 17.7761 6 17.5 6H15V15C15 16.1046 14.1046 17 13 17H5C3.89543 17 3 16.1046 3 15V14.5C3 14.2239 3.22386 14 3.5 14H5V5.5C5 4.11929 6.11929 3 7.5 3ZM11 15C11 15.3643 11.0974 15.7058 11.2676 16H5C4.44772 16 4 15.5523 4 15H5.5H11ZM7.5 7C7.22386 7 7 7.22386 7 7.5C7 7.77614 7.22386 8 7.5 8H12.5C12.7761 8 13 7.77614 13 7.5C13 7.22386 12.7761 7 12.5 7H7.5ZM7.5 10C7.22386 10 7 10.2239 7 10.5C7 10.7761 7.22386 11 7.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H7.5Z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E");
}
.bom-header .close-icon {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L10 9.29289L14.6464 4.64645C14.8417 4.45118 15.1583 4.45118 15.3536 4.64645C15.5488 4.84171 15.5488 5.15829 15.3536 5.35355L10.7071 10L15.3536 14.6464C15.5488 14.8417 15.5488 15.1583 15.3536 15.3536C15.1583 15.5488 14.8417 15.5488 14.6464 15.3536L10 10.7071L5.35355 15.3536C5.15829 15.5488 4.84171 15.5488 4.64645 15.3536C4.45118 15.1583 4.45118 14.8417 4.64645 14.6464L9.29289 10L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z' fill='%23141420'/%3E%3C/svg%3E%0A");
}

/* Tailwind is resizing text inputs to 13px, make this 14px to match the cell text size */
#portal input[type="text"] {
  font-size: 14px;
}

/* Tailwind is causing a blue line to appear over text areas */
#portal textarea:focus {
  box-shadow: none;
}

#portal input:focus {
  box-shadow:none;
  border-radius:0;
}

/* Hide profiler for demos */
.profiler-results.profiler-results { display: none !important; }

.part-detail .graph {
  margin-top: 6px;
  margin-bottom: -16px;
}

.part-detail.history-row:last-child .graph .line {
  display: none;
}
