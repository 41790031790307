.dropdown-trigger {
  position: relative;
  display: inline-flex;
  transition: 0.2s transform;

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }

  &:hover:after {
    opacity: 0.9;
  }
}

.dropdown-body {
  position: absolute;
  z-index: 20;

  top: calc(100% + 10px);
  @apply bg-white border border-gray-50 rounded;
  box-shadow: 0px 0px 10px rgba(20, 20, 32, 0.1);
  transform: translateY(10px) scale(0.98);
  opacity: 0;

  .open-left & {
    left: -9999px;
    transition: left 0s 0.4s, opacity 0.4s, transform 0.4s;
  }

  .open-right & {
    right: 9999px;
    transition: right 0s 0.4s, opacity 0.4s, transform 0.4s;
  }

  .open-left.active & {
    transform: none;
    opacity: 1;
    left: 0;
    transition: left 0s, opacity 0.4s, transform 0.4s;
  }

  .open-right.active & {
    transform: none;
    opacity: 1;
    right: 0;
    transition: right 0s, opacity 0.4s, transform 0.4s;
  }

  &.small {
    width: 200px;
  }

  &.medium {
    width: 270px;
  }

  &.large {
    width: 340px;
  }

  &.small-height,
  &.medium-height,
  &.large-height {
    overflow-y: auto;
  }

  &.small-height {
    max-height: 140px;
  }

  &.medium-height {
    max-height: 200px;
  }

  &.large-height {
    max-height: 300px;
  }

  h4 {
    text-transform: uppercase;
    @apply text-tertiary p-4;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 12px;
  }
}
