.footer {
  @apply py-8 px-0;
  font-size: 12px;
  text-align: center;
  margin-top: auto;

  @media (min-width: 1024px) {
    text-align: left;
  }

  .center {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
    }

    @media (min-width: 1240px) {
      align-items: center;
    }
  }

  ul {
    @media (min-width: 1024px) {
      display: inline-flex;
    }
  }

  li + li {
    margin-top: 5px;

    @media (min-width: 1024px) {
      margin-top: 0;
    }
  }

  li {
    display: inline-block;
    margin: 0 20px 5px 0;

    @media (min-width: 1240px) {
      margin: 0 20px 0 0;
    }
  }

  a {
    transition: 0.2s color;
    @apply text-tertiary;

    &:hover {
      @apply text-secondary;
    }
  }

  a span {
    display: none;

    @media (min-width: 1240px) {
      display: inline;
    }
  }

  svg {
    display: inline-block;
    margin-top: -2px;
    margin-right: 5px;
    vertical-align: middle;
    width: 14px;
  }
}

.footer-left {
  @media (min-width: 1240px) {
    display: flex;
    align-items: center;
  }
}

.footer-copy {
  margin-bottom: 20px;
  @apply text-tertiary;

  @media (min-width: 1024px) {
    margin-bottom: 10px;
  }

  @media (min-width: 1240px) {
    margin-bottom: 0;
    margin-right: 20px;
  }

  svg {
    width: 20px;
  }
}

.footer-sell {
  @apply text-tertiary;
  margin: 20px 0;

  @media (min-width: 1024px) {
    margin: 5px 0;
  }

  @media (min-width: 1240px) {
    margin: 0;
  }
}

.footer-right {
  @media (min-width: 1024px) {
    margin-left: 20px;
  }
}
