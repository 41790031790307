.upload-thumb {
  @media (min-width: 1024px) {
    width: calc(33.3333% - 17px);
  }

  & + & {
    margin-top: 25px;

    @media (min-width: 1024px) {
      margin: 0 0 0 25px;
    }
  }

  h3 {
    margin-bottom: 10px;
  }
}

.upload-thumb-image {
  position: relative;
  padding-bottom: 65%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
}
