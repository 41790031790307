.sub-header {
  p {
    font-size: 14px;
  }
}

.sub-header-top {
  display: flex;
  align-items: center;
}

.sub-header-top-flex {
  flex: 1;
  margin: 0 10px;
  @apply text-secondary;

  & > * + * {
    margin-top: 5px;
  }

  h2 {
    @apply text-primary;
  }
}

.sub-header-bottom {
  margin-top: 10px;

  & > * + * {
    margin-top: 15px;
  }

  p {
    @apply text-secondary;
  }

  hr {
    border: none;
    height: 1px;
    @apply bg-gray-50;
  }
}
