button[aria-label][data-balloon-pos] {
  overflow: visible;
}

[aria-label][data-balloon-pos] {
  position: relative;
  cursor: pointer;
}
[aria-label][data-balloon-pos]:after {
  @apply text-sm absolute border-2 bg-black text-white z-30 py-0.5 px-1 shadow-sm rounded opacity-0 border-black;
  cursor: none;
  transition: all 0.1s ease-out 0.1s;
  text-indent: 0;
  font-weight: normal;
  font-style: normal;
  text-shadow: none;
  content: attr(aria-label);
  white-space: nowrap;
  pointer-events: none;
}
[aria-label][data-balloon-pos]:before {
  border-top: 1px solid rgba(31, 41, 55, 1);
  width: 0;
  height: 0;
  border: 5px solid transparent;
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s ease-out 0.1s;
  content: "";
  position: absolute;
  z-index: 30;
  display:none;
  pointer-events:none;
}
[aria-label][data-balloon-pos]:hover:before,
[aria-label][data-balloon-pos]:hover:after,
[aria-label][data-balloon-pos][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-visible]:after,
[aria-label][data-balloon-pos]:not([data-balloon-nofocus]):focus:before,
[aria-label][data-balloon-pos]:not([data-balloon-nofocus]):focus:after {
  opacity: 1;
  pointer-events: none;
}
[aria-label][data-balloon-pos][data-balloon-break]:after {
  white-space: pre;
}
[aria-label][data-balloon-pos][data-balloon-break][data-balloon-length]:after {
  white-space: pre-line;
  word-break: break-word;
}
[aria-label][data-balloon-pos][data-balloon-blunt]:before,
[aria-label][data-balloon-pos][data-balloon-blunt]:after {
  transition: none;
}
[aria-label][data-balloon-pos][data-balloon-delay]:before,
[aria-label][data-balloon-pos][data-balloon-delay]:after {
  transition: none;
}
[aria-label][data-balloon-pos][data-balloon-delay]:hover:before,
[aria-label][data-balloon-pos][data-balloon-delay]:hover:after {
  transition: all 0.15s cubic-bezier(.26,1.25,.64,1) 0.7s;
}
[aria-label][data-balloon-pos][data-balloon-pos="up"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos="up"][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos="down"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos="down"][data-balloon-visible]:after {
  transform: translate(-50%, 0);
}
[aria-label][data-balloon-pos][data-balloon-pos="up"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos="up"][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos="down"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos="down"][data-balloon-visible]:before {
  transform: translate(-50%, 0);
}
[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:after {
  left: 0;
}
[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:before {
  left: 5px;
}
[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:after {
  right: 0;
}
[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:before {
  right: 5px;
}
[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos*="-left"][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos*="-right"][data-balloon-visible]:after {
  transform: translate(0, 0);
}
[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos*="-left"][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos*="-right"][data-balloon-visible]:before {
  transform: translate(0, 0);
}
[aria-label][data-balloon-pos][data-balloon-pos^="up"]:before,
[aria-label][data-balloon-pos][data-balloon-pos^="up"]:after {
  bottom: 100%;
  transform-origin: top;
  transform: translate(0, 2px);
}
[aria-label][data-balloon-pos][data-balloon-pos^="up"]:after {
  @apply mb-2;
}
[aria-label][data-balloon-pos][data-balloon-pos="up"]:before,
[aria-label][data-balloon-pos][data-balloon-pos="up"]:after {
  left: 50%;
  transform: translate(-50%, 2px);
}
[aria-label][data-balloon-pos][data-balloon-pos^="down"]:before,
[aria-label][data-balloon-pos][data-balloon-pos^="down"]:after {
  top: 100%;
  transform: translate(0, calc(2px * -1));
}
[aria-label][data-balloon-pos][data-balloon-pos^="down"]:after {
  margin-top: 10px;
}
[aria-label][data-balloon-pos][data-balloon-pos^="down"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: #141420;
}
[aria-label][data-balloon-pos][data-balloon-pos="down"]:after,
[aria-label][data-balloon-pos][data-balloon-pos="down"]:before {
  left: 50%;
  transform: translate(-50%, calc(2px * -1));
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos="left"][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos="right"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos="right"][data-balloon-visible]:after {
  transform: translate(0, -50%) scale(1);
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos="left"][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos="right"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos="right"][data-balloon-visible]:before {
  transform: translate(0, -50%) scale(1);
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:after,
[aria-label][data-balloon-pos][data-balloon-pos="left"]:before {
  right: 100%;
  top: 50%;
  transform: translate(2px, -50%);
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:after {
  margin-right: 10px;
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: #141420;
}
[aria-label][data-balloon-pos][data-balloon-pos="right"]:after,
[aria-label][data-balloon-pos][data-balloon-pos="right"]:before {
  left: 100%;
  top: 50%;
  transform-origin: center left;
  transform: translate(calc(2px * -1), -50%) scale(.8);
}
[aria-label][data-balloon-pos][data-balloon-pos="right"]:after {
  margin-left: 15px;
}
[aria-label][data-balloon-pos][data-balloon-pos="right"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: #141420;
}
[aria-label][data-balloon-pos][data-balloon-length]:after {
  white-space: normal;
}
[aria-label][data-balloon-pos][data-balloon-length="small"]:after {
  width: 80px;
}
[aria-label][data-balloon-pos][data-balloon-length="medium"]:after {
  width: 150px;
}
[aria-label][data-balloon-pos][data-balloon-length="large"]:after {
  width: 260px;
}
[aria-label][data-balloon-pos][data-balloon-length="xlarge"]:after {
  width: 380px;
}
@media screen and (max-width: 768px) {
  [aria-label][data-balloon-pos][data-balloon-length="xlarge"]:after {
    width: 90vw;
  }
}
[aria-label][data-balloon-pos][data-balloon-length="fit"]:after {
  width: 100%;
}
