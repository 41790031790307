.field + .field {
  margin-top: 12px;
}

label {
  @apply text-secondary;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
}

input[type="date"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="password"],
input[type="search"],
input[type="number"],
textarea,
trix-editor,
select,
.input {
  @apply bg-white border border-gray-50 text-primary;
  font-size: 14px;
  transition: 0.2s border;
  border-radius: 5px;

  &:disabled {
    pointer-events: none;
    @apply bg-gray-25;
    cursor: not-allowed;
  }

  &:hover {
    @apply border border-gray-200;
  }

  &:focus {
    @apply border border-blue;
  }

  &::placeholder {
    @apply text-tertiary;
  }
}

input:read-only{
  @apply bg-gray-25
}

input[type="date"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="password"],
input[type="search"],
input[type="number"],
.input {
  line-height: 20px;
  @apply py-1 px-2;
}

input[type="search"] {
  background-position: left 6px center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding-left: 32px;
}

textarea {
  @apply p-2;
  max-height: 120px;
}

select {
  height: 32px;
  @apply py-0 px-2;
  background-position: right 12px center;
  background-repeat: no-repeat;
  padding-right: 32px;
}

input[type="radio"] {
  @apply border-gray-100 border;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

input[type="radio"]:disabled {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  @apply bg-gray-25;
  pointer-events: none;
}

input[type="radio"]:focus {
  @apply shadow-sm border border-blue;
  box-shadow: inset 0px 0px 0px 3px #c0c0c4, 0px 0px 0px 2px #ebf3fd;
}

input[type="radio"]:hover {
  @apply border border-gray-300;
  box-shadow: inset 0px 0px 0px 3px #c0c0c4;
}

input[type="radio"]:checked {
  @apply bg-white;
  @apply border border-blue;
  box-shadow: inset 0px 0px 0px 3px #2f80ed;
}

input[type="radio"]:checked:disabled {
  pointer-events: none;
  box-shadow: inset 0px 0px 0px 3px #c0c0c4;
  @apply border border-gray-200;
}

input[type="radio"],
input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px 0 6px;
}

input[type="checkbox"] {
  @apply border border-gray-100;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  transition: 0.2s border-color;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

input[type="checkbox"]:hover {
  @apply border border-gray-200;
}

input[type="checkbox"]:focus {
  @apply border border-blue;
  box-shadow: 0px 0px 0px 2px #ebf3fd;
}

input[type="checkbox"]:disabled {
  pointer-events: none;
  @apply border border-gray-50;
  @apply bg-gray-25;
}

input[type="checkbox"].partial:checked {
  @apply border border-blue;
  @apply bg-blue;
  box-shadow: inset 0px 0px 0px 3px #FFF;
}

input[type="checkbox"]:checked {
  @apply bg-blue border border-blue-600;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.3535 5.64645C16.1583 5.45118 15.8417 5.45118 15.6464 5.64645L7.99997 13.2929L4.85352 10.1464C4.65826 9.95118 4.34168 9.95118 4.14642 10.1464C3.95115 10.3417 3.95115 10.6583 4.14642 10.8536L7.64642 14.3536C7.84168 14.5488 8.15826 14.5488 8.35352 14.3536L16.3535 6.35355C16.5488 6.15829 16.5488 5.84171 16.3535 5.64645Z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
}

input[type="checkbox"]:checked:disabled {
  @apply bg-gray-300 border-gray-300
}

input[type="checkbox"].red {
  background-image: none;

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.3535 5.64645C16.1583 5.45118 15.8417 5.45118 15.6464 5.64645L7.99997 13.2929L4.85352 10.1464C4.65826 9.95118 4.34168 9.95118 4.14642 10.1464C3.95115 10.3417 3.95115 10.6583 4.14642 10.8536L7.64642 14.3536C7.84168 14.5488 8.15826 14.5488 8.35352 14.3536L16.3535 6.35355C16.5488 6.15829 16.5488 5.84171 16.3535 5.64645Z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
  }
}

input[type="checkbox"].red:checked,
input[type="checkbox"].red {
  @apply bg-red border border-red;
}

input[type="checkbox"].red:focus {
  @apply border-red border shadow-none;
}

input[type="file"] {
  padding-left: 2px;
}

.descriptive-checkbox,
.descriptive-radio {
  & + & {
    padding-top: 15px;
  }

  label {
    padding-top: 3px;
  }

  label strong {
    font-weight: 400;
    font-size: 14px;
    display: block;
    margin-bottom: 2px;
    line-height: 1.2;
  }

  input:checked + label strong {
    @apply text-primary;
  }

  label {
    @apply text-secondary;
    font-weight: 400;
  }

  input {
    margin-top: 3px;
  }

  input[type="radio"] + label,
  input[type="checkbox"] + label {
    margin-left: 10px;
  }
}

.sr-only {
  label {
    position: absolute;
    width: 1px;
    height: 1px;
    @apply p-0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}

legend {
  font-weight: 600;
  font-size: 16px;
  @apply text-primary py-2 px-0;
}

.form-help-text {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 12px;
}

form .commodity-code-field {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.errors {
    padding-bottom: 50px;
  }

  .error {
    position: absolute;
    left: 10px;
    right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  input,
  .part-number-separator,
  .part-number-delimiter {
    font-family: "Menlo", "Consolas", "Courier", monospace;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    color: #14142099;
    border-width: 0px;
  }

  .part-number-delimiter,
  .part-number-separator {
    color: rgba(20, 20, 32, 0.1);
  }

  .part-number-separator {
    margin-left: 10px;
    margin-right: 10px;
  }

  input[name="part_identifier_example"],
  input[name="commodity_code_example"],
  input[name="part_number_example"] {
    background-color: white;
    color: rgba(20, 20, 32, 0.2);
  }

  input[name="part_number_example"] {
    width: 170px;
  }

  label.invisible {
    visibility: hidden;
  }
}

trix-editor.frameless,
textarea.frameless {
  @apply p-0 border-0 resize-none
}

trix-editor.frameless:focus,
textarea.frameless:focus {
  @apply outline-none ring-0
}

textarea.heading {
  @apply text-lg font-semibold text-primary min-h-[21px]
}

trix-editor.full-height {
  @apply min-h-full flex-grow
}
