@keyframes fade {
  from {
    opacity: 0.5
  }
}

@keyframes expand {
  from {
    transform: scale(.99);
  }
}

.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  animation: fade 0.2s cubic-bezier(0.33, 1, 0.68, 1)
}

.modal-box {
  z-index: 100;
  animation: expand 0.2s cubic-bezier(0.33, 1, 0.68, 1);
  border-radius: 8px;
  @apply bg-white;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.15);
  margin: auto;
  max-width: 770px;
  width: calc(100% - 40px);
  max-height: calc(100% - 40px);

  @media (min-width: 1024px) {
    width: calc(100% - 80px);
    max-height: calc(100% - 80px);
  }

  .small & {
    max-width: 400px;
  }

  .medium & {
    max-width: 560px;
  }

  .large & {
    max-width: 840px;
  }

  .xl & {
    max-width: 1000px;
  }
}

.modal-body {
  @apply p-5 pt-0;
}

.modal-content {
  max-height: calc(100vh - 60vh);
  overflow-y: auto;
}

.modal-well {
  @apply border-t border-gray-50;
  @apply p-5;
  @apply bg-gray-25;
}

.modal-header {
  @apply p-5 pb-3;
  display: flex;
  align-items: flex-start;
}

.modal-footer {
  @apply border-t border-gray-50 py-2 px-5;
}

.modal-header-close {
  opacity: 0.6;
  transition: 0.2s opacity;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
