.avatar-group {
  .avatar + .avatar {
    margin-left: -6px;
  }
}

.avatar-group-more {
  @apply bg-gray-50 border-2 border-white;
  height: 28px;
  background: #eee;
  padding: 0 8px 1px 5px;
  border-radius: 14px;
  font-weight: 700;
  display: inline-flex;
  margin-left: -8px;
  font-size: 12px;
  align-items: center;
}

.avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-flex;
  background: #eee;
  @apply border-2 border-white;

  &.xl {
    width: 48px;
    height: 48px;
  }

  &.small {
    width: 20px;
    height: 20px;
    @apply border border-white;
  }

  &.offline,
  &.online {
    position: relative;
  }

  &.offline:after,
  &.online:after {
    position: absolute;
    content: "";
    bottom: -2px;
    right: -2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    @apply border border-white;
  }

  &.offline:after {
    @apply bg-red;
  }

  &.online:after {
    @apply bg-green;
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
  }

  &.small img {
    width: 18px;
    height: 18px;
  }

  &.xl img {
    width: 44px;
    height: 44px;
  }
}
