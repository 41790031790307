import { styled } from "@linaria/react";
export const MarkdownContainer = styled.div`
    word-break: break-word;
    -webkit-touch-callout: default;
    padding-top: 6px;

    > * {
        margin: 0;
    }

    & *:last-child {
        margin-bottom: 0;
    }

    & p img {
        width: 100%;
    }
`;
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsiL1VzZXJzL3B6aWNoL1Byb2plY3RzL3B6Y2ZnL2dsaWRlLWRhdGEtZ3JpZC9wYWNrYWdlcy9jb3JlL3NyYy9tYXJrZG93bi1kaXYvcHJpdmF0ZS9tYXJrZG93bi1jb250YWluZXIudHN4Il0sCiAgInNvdXJjZXNDb250ZW50IjogWyJpbXBvcnQgeyBzdHlsZWQgfSBmcm9tIFwiQGxpbmFyaWEvcmVhY3RcIjtcblxuZXhwb3J0IGNvbnN0IE1hcmtkb3duQ29udGFpbmVyID0gc3R5bGVkLmRpdmBcbiAgICB3b3JkLWJyZWFrOiBicmVhay13b3JkO1xuICAgIC13ZWJraXQtdG91Y2gtY2FsbG91dDogZGVmYXVsdDtcbiAgICBwYWRkaW5nLXRvcDogNnB4O1xuXG4gICAgPiAqIHtcbiAgICAgICAgbWFyZ2luOiAwO1xuICAgIH1cblxuICAgICYgKjpsYXN0LWNoaWxkIHtcbiAgICAgICAgbWFyZ2luLWJvdHRvbTogMDtcbiAgICB9XG5cbiAgICAmIHAgaW1nIHtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgfVxuYDtcbiJdLAogICJtYXBwaW5ncyI6ICJBQUFBO0FBRU8sYUFBTSxvQkFBb0IsT0FBTztBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBOyIsCiAgIm5hbWVzIjogW10KfQo=*/