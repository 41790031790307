.tribute-container {
  position: relative;
  z-index: 49;
  
}
.tribute-container ul {
  width: 200px;
  position: absolute;
  top: calc(100% + 10px);
  @apply bg-white;
  box-shadow: 0px 0px 10px rgba(20, 20, 32, 0.1);
  border-radius: 5px;
  transform: translateY(10px) scale(0.98);
  @apply border border-gray-50;

}
.tribute-container li {
  @apply py-2 px-3;
  cursor: pointer;
}
.tribute-container li.highlight {
  @apply bg-blue-100;
}
.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}
