.collapsible-list-header {
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  @apply text-secondary;

  .active &,
  &:hover {
    h3 {
      @apply text-primary;
    }
  }

  h3 {
    font-weight: 400;
    @apply text-secondary;
    transition: 0.5s color;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.39037 4.51199L7.35005 0.812378C7.61196 0.484996 7.37887 3.05932e-05 6.95962 3.05882e-05L1.04025 3.05176e-05C0.620992 3.05126e-05 0.387906 0.484997 0.649811 0.812378L3.6095 4.51199C3.80966 4.76219 4.1902 4.76219 4.39037 4.51199Z' fill='%23141420'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    transition: 0.5s opacity, 0.5s transform;
    transform: translateY(-50%) rotate(-90deg);
  }

  .active &:before,
  &:hover:before {
    opacity: 0.6;
  }

  .active &:before {
    transform: translateY(-50%);
  }
}

.collapsible-list-body {
  padding-left: 30px;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s max-height;
}

.collapsible-list-body-content {
  padding-top: 15px;
}
