.error {
  @apply bg-red-200 py-1 px-3;
  color: #971212;
  border: 1px solid #f1cbcb;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.notice {
  @apply bg-yellow-200 py-1 px-3;
  color: #9a770b;
  border: 1px solid #f9e6ab;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: 5px;
}

