.autocomplete-empty {
  min-height: 32px;

  &.alternate {
    @apply border border-gray-50 bg-white;
    font-size: 13px;
    transition: 0.2s border;
    border-radius: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.5815 12.8744C14.4664 11.8291 15 10.4769 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C10.4769 15 11.8291 14.4664 12.8744 13.5815L16.1464 16.8536C16.3417 17.0488 16.6583 17.0488 16.8536 16.8536C17.0488 16.6583 17.0488 16.3417 16.8536 16.1464L13.5815 12.8744ZM14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4C11.7614 4 14 6.23858 14 9Z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E");
    background-position: left 6px center;
    background-repeat: no-repeat;
    background-size: 18px;
    padding-left: 32px;
  }

  &.alternate:hover {
    @apply border border-gray-200;
  }
}

.autocomplete-field .list-group {
  @apply py-2 px-0;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(20, 20, 32, 0.1);
  margin-top: 0px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;

  li {
    @apply py-1 px-3;
  }
}

.autocomplete-field-input {
  width: 100%;
  position: relative;
}

.autocomplete-field-input-tags {
  position: absolute;
  top: 16px;
  left: 30px;
  transform: translateY(-50%);
}
