.progress {
  display: flex;
  position: relative;
  z-index: 10;
}

.progress-segment {
  margin-top: -1px;
  @apply bg-gray-25 border-gray-50;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 0px;
  border-left-width: 0px;
  height: 10px;

  &.fill {
    flex: 1;
  }

  &:first-of-type {
    border-left-width: 1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-of-type {
    border-right-width: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & + & {
    border-left: none;
  }

  &.purple {
    @apply bg-purple border-purple-600;
  }

  &.red {
    @apply bg-red border-red-600;
    height: 10px;
  }

  &.orange {
    @apply bg-orange border-orange-600;
  }

  &.yellow {
    @apply bg-yellow border-yellow-600;
  }

  &.green {
    @apply bg-green border-green-600;
  }

  &.blue {
    @apply bg-blue border-blue-600;
  }
  &.gray {
    @apply bg-gray border-gray-600;
  }

  &.purple-200 {
    @apply bg-purple-200 border-purple-300;
  }

  &.red-200 {
    @apply bg-red-200 border-red-300;
    height: 10px;
  }

  &.orange-200 {
    @apply bg-orange-200 border-orange-300;
  }

  &.yellow-200 {
    @apply bg-yellow-200 border-yellow-300;
  }

  &.green-200 {
    @apply bg-green-200 border-green-300;
  }

  &.blue-200 {
    @apply bg-blue-200 border-blue-300;
  }
}
