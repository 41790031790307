.masonry {
  @media (min-width: 1024px) {
    columns: 2;
    column-gap: 30px;
  }
}

.masonry-item {
  margin-bottom: 30px;

  @media (min-width: 1024px) {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    margin: 0;
    padding-bottom: 30px;
  }
}
