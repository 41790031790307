.pagy.nav,
.pagy.nav-js {
  @apply flex border rounded space-x-0.1 justify-center w-full;
}

.pagy.nav a,
.pagy.nav-js .page a {
  @apply block px-3 py-1 text-base text-primary border -m-0.1;
}

.pagy.nav a:first-child {
  @apply !mr-auto rounded-l
}

.pagy.nav a:last-child {
  @apply !ml-auto rounded-r
}

.pagy a:not(.gap):not([href]),
.pagy-combo.nav-js a:not(.gap):not([href]){ /* disabled links */
  @apply text-tertiary cursor-default;
}

.pagy.nav .current,
.pagy.nav-js .current {
  @apply text-primary cursor-default bg-gray-25 font-semibold;
}

.pagy.nav .gap {
  @apply block px-3 py-1 text-tertiary;
}


.pagy-combo.nav-js {
  @apply flex max-w-max rounded-full px-3 py-1 text-sm text-secondary font-semibold bg-gray-200 shadow-md;
}

.pagy-combo.nav-js .pagy-combo-input {
  @apply bg-white px-2 rounded-sm
}

